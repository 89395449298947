<template>
  <div>
    <!--begin::DocumentVQA-->
    <div class="row">
      <div class="col-lg-12 col-xxl-12">
        <p>
          Question Answering and Natural Language Inference over Tables are Key
          Information Extraction, also referred to as Property Extraction, is a
          task where (properties, document) tuple values are to be provided.
          Contrary to QA problems, there is no question in natural language but
          rather a phrase or keyword, such as <em>total amount</em>,
          <em>place of birth</em>, or <em>invoice number</em>.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-xxl-8">
        <KIE :list="list"></KIE>
      </div>

      <div class="col-xxl-4">
        <StatWidget7
          category="Key Information Extraction"
          :progress="list.map(a => a.kie).reverse()"
          color="danger"
        ></StatWidget7>
        <Paper></Paper>
      </div>
    </div>
    <!--end::DocumentVQA-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KIE from "@/view/content/widgets/list/KIE.vue";
import StatWidget7 from "@/view/content/widgets/stats/Widget12.vue";
import Paper from "@/view/content/widgets/due/Paper.vue";
import { mapState } from "vuex";

export default {
  name: "kie",
  components: {
    KIE,
    StatWidget7,
    Paper
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Leaderboard" },
      { title: "Key Information Extraction" }
    ]);
  },
  computed: {
    ...mapState({
      list: state =>
        state.auth.leaderboard
          .filter(item => item.kie > 0)
          .sort((a, b) => {
            return a.kie - b.kie;
          })
          .reverse()
    })
  }
};
</script>
